import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './apps/default/App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'; // Required for bootstrap styles
import "regenerator-runtime/runtime.js"; // Required for async methods to work (or is it?)

// import { extend } from 'jquery'; // jquery is required by bootstrap
// import { Modal } from 'bootstrap';
// import { ModalBody } from 'react-bootstrap';
// import BForm from 'react-bootstrap/Form';
// import 'bootstrap';
// import 'react-bootstrap';
// import 'jquery'; // required by bootstrap
// import 'popper.js'; // required by bootstrap

// FIXME: Check imports and maybe move to correct place or remove

//
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
