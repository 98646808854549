
export const NODE_ENV : string = process?.env?.NODE_ENV ?? 'development';

export const IS_PRODUCTION  : boolean = NODE_ENV === 'production';
export const IS_DEVELOPMENT : boolean = NODE_ENV === 'development';

/**
 * This is the header property name for token
 */
export const BACKEND_SESSION_HEADER : string = "jwt-token";

/**
 * This is the keyword for localstorage on the frontend side
 */
export const SESSION_LOCALSTORAGE_KEY : string = "jwt-token";

/**
 * This is only used in the development mode.
 */
export const DEV_PROXY_BACKEND_URL = process.env?.REACT_APP_REMOTE_BACKEND_URL ?? 'https://ledfoil-mq.fsol.fi/api/';

export const API_ADDR : string = process?.env?.REACT_APP_BACKEND_URL ?? ( IS_PRODUCTION ? 'https://ledfoil-mq.fsol.fi/api/' : 'http://localhost:3000/api/');
