import RoutePath from "../constants/RoutePath";

export class RouteUtils {

    public static getPlaylistEditRoutePath (id : number) : RoutePath {
        return RoutePath.PLAYLIST_COLLECTION + "/" + encodeURIComponent(id) as RoutePath;
    }

}

export default RouteUtils;
