import * as React from "react";
import {MAIN_MENU_CLASS_NAME} from "../../constants/classNames";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import MenuItem from "../menuItem/MenuItem";
import BackendService, {BackendServiceEvent, PlaylistDTO} from "../../services/BackendService";
import {map} from "../../modules/lodash";
import RouteService from "../../services/RouteService";
import RoutePath from "../../constants/RoutePath";
import LogService from "../../services/LogService";

const LOG = LogService.createLogger('MainMenu');

export interface MainMenuProps {
    className?: string;
}

export interface Playlist {

    name       : string;

    playlistId : number;

}

export interface MainMenuState {

    playlists: Array<Playlist> | undefined;

}

interface ClickCallback {
    (): void;
}

export class MainMenu extends React.Component<MainMenuProps, MainMenuState> {

    private readonly _onClickCallback : ClickCallback;

    static defaultProps: Partial<MainMenuProps> = {};

    constructor (props: MainMenuProps) {

        super(props);

        this.state = {
            playlists: undefined
        };

        this._onClickCallback = this._onClick.bind(this);

    }

    componentDidMount() {

        BackendService.on(BackendServiceEvent.PLAYLIST_DELETE, (event, playlistUrl : string) => {

            // FIXME: This might end in a race condition

            this._fetchData().catch(err => {
                LOG.error('Error: ', err);
            });

        });

        this._fetchData().catch(err => {
            LOG.error('Error: ', err);
        });

    }

    render() {

        const playlists = this.state.playlists;

        LOG.debug("Playlists: ", playlists);

        return (
            <div className={MAIN_MENU_CLASS_NAME + ' ' + (this.props.className ?? '')}>

                <Table striped hover>
                    <tbody>
                    {map(playlists, (child : Playlist, index : number) => <MenuItem key={child.playlistId}  playlist={child} />)}
                    </tbody>
                </Table>

                <Button onClick={this._onClickCallback}>New playlist</Button>

            </div>
        );

    }

    private async _fetchData () {

        LOG.debug('Updating view data...');

        const playlists = await BackendService.getPlaylists();

        const array_of_playlists : Array<Playlist> = map(playlists?.playlists ?? [], (item: PlaylistDTO) => {

            LOG.debug("Playlist: ", item, "PlaylistName: ", item.playlistName, ", PlaylistId: ", item.playlistId)

            return {
                name: item.playlistName,
                playlistId: item.playlistId
            };

        });

        this.setState({
            ...this.state,
            playlists: array_of_playlists
        });

    }

    // noinspection JSMethodCanBeStatic
    private _onClick () {
        RouteService.setRouteTarget(RoutePath.NEW_PLAYLIST);
    }

}

export default MainMenu;
