import React from "react";
import BackendService from "../../services/BackendService";
import {MediaFile} from "../uploadFile/UploadFile";
import { Trash } from "../../modules/icons";
import LogService from "../../services/LogService";

const LOG = LogService.createLogger('MediaFileItem');

export interface MediaFileItemProps {

    media_file: MediaFile;

}

export interface MediaFileItemState {

    show: boolean;

    image: string | undefined;

}

export class MediaFileItem extends React.Component<MediaFileItemProps, MediaFileItemState> {

    constructor(props : MediaFileItemProps) {

        super(props);

        this.state = {
            show: false,
            image: undefined
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);

    }

    componentDidMount() {

        LOG.debug('App mounted');

        this._loadData().catch(err => {
            LOG.error('ERROR: ', err);
        });

    }

    handleShow() {
        if (!this.state.show) {
            this.setState({
                ...this.state,
                show: true
            });
        }
    }

    handleClose() {
        if (this.state.show) {
            this.setState({
                ...this.state,
                show: false
            });
        }
    }

    async deleteHandler() {

        let response = await BackendService.deleteMediaFile(this.props.media_file["playlistUrl"]);

        //var response_value = JSON.parse(json);

        if(response?.success === false) {
            LOG.debug("Could not delete media file, message: ", response?.msg);
            alert(response?.msg);
        }
        else if(response?.success) {
            LOG.debug("File deleted");
            alert("File deleted");
        }

    }

    render() {
        return (
            <tr id={this.props.media_file["elementName"]}>
                <td><img src={this.state.image} alt={this.props.media_file["elementName"]} /></td>
                <td><label>{this.props.media_file["elementName"]}</label></td>
                
                <td><button className="btn btn-outline-secondary" onClick={this.deleteHandler} id={this.props.media_file["elementName"]}><Trash /></button></td>

            </tr>
        )
    }

    private async _loadData () {

        LOG.debug('playlistUrl: ' + this.props.media_file["playlistUrl"]);

        let response = await BackendService.getMediaFile(this.props.media_file["playlistUrl"]);
        let responseBlob = await response.blob();
        if(!response.ok) {
            LOG.debug("Failed to download image");
        } else {
            //LOG.debug("Blob: ", responseBlob);
            this.setState({image: window.URL.createObjectURL(responseBlob)});
        }

    }

}

export default MediaFileItem;
