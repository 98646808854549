import * as React from "react";
// import "./LogoutView.scss";
import {LOGOUT_VIEW_CLASS_NAME} from "../../constants/classNames";
import SessionService from "../../services/SessionService";
import RouteService from "../../services/RouteService";
import RoutePath from "../../constants/RoutePath";

export interface LogoutViewProps {
    className?: string;
}

export interface LogoutViewState {

}

export class LogoutView extends React.Component<LogoutViewProps, LogoutViewState> {

    static defaultProps: Partial<LogoutViewProps> = {};

    componentDidMount() {

        SessionService.unsetSessionToken();

        RouteService.setRouteTarget( RoutePath.INDEX );

    }

    render() {

        return (
            <div className={LOGOUT_VIEW_CLASS_NAME + ' ' + (this.props.className ?? '')}>
                Logging out...
            </div>
        );

    }

}

export default LogoutView;
