import React from "react";
import UploadFile from "../../components/uploadFile/UploadFile";

export interface UploadViewState {

}

export interface UploadViewProps {

}

export class UploadView extends React.Component<UploadViewProps, UploadViewState> {

    render () {

        return (
            <div>

                <UploadFile />

            </div>
        );

    }

}

export default UploadView;
