import React from "react";
import {isFunction} from "../../modules/lodash";
import {API_ADDR} from "../../constants/backend";
import {Playlist} from "../mainMenu/MainMenu";
import RouteService from "../../services/RouteService";
import RouteUtils from "../../services/RouteUtils";
import { Pencil, Clipboard } from "../../modules/icons";

export interface MenuItemProps {

    playlist: Playlist;

}

export interface MenuItemState {

}

export class MenuItem extends React.Component<MenuItemProps, MenuItemState> {

    constructor(props : MenuItemProps) {
        super(props);
        this.editHandler = this.editHandler.bind(this);
        this.copyHandler = this.copyHandler.bind(this)
    }

    async editHandler() {

        RouteService.setRouteTarget( RouteUtils.getPlaylistEditRoutePath(this.props.playlist.playlistId) );

    }

    copyHandler () {

        const path = document.getElementById(this.props.playlist["name"] + "Path");

        // FIXME: Fix this. HTMLElement does not have select method
        // @ts-ignore
        if (isFunction(path?.select)) {
            // @ts-ignore
            path.select();
        }

        document.execCommand("copy");

        alert("Copied api request address to clipboard")

    }

    render () {

        return (
            <tr id={this.props.playlist["name"]}>
                <td><h5>{this.props.playlist["name"]}</h5></td>
                <td>
                    <div className="input-group mb-3">
                        <input id={this.props.playlist["name"] + "Path"} type="text" className="form-control" defaultValue={API_ADDR + "playlists/" + this.props.playlist["playlistId"]} readOnly/>
                        <div className="input-group-append">
                            <button onClick={this.copyHandler} className="btn btn-outline-secondary" type="button"><Clipboard /> </button>
                        </div>
                    </div>
                </td>
                <td><button className="btn btn-outline-secondary" onClick={this.editHandler} id={this.props.playlist["name"]}>Edit <Pencil /></button></td>
            </tr>
        );

    }

}

export default MenuItem;
