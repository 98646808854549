import {API_ADDR} from "../constants/backend";
import LogService from "./LogService";
import SessionService from "./SessionService";

const LOG = LogService.createLogger('LoginService');

export interface LoginDTO {

    success: boolean;
    token ?: string;

}

export interface LoginResponse {

    success: boolean;

}

export class LoginService {

    public static async login (username: string, password: string) : Promise<LoginResponse> {

        LOG.debug('Logging as ', username, password);

        const fetchUrl = API_ADDR + 'login?user_name=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password);

        LOG.debug('URL = ', fetchUrl);

        const response = await fetch(fetchUrl);

        LOG.debug('response = ', response);

        const response_result : Record<string, any> = await (response.json());

        LOG.debug('response_result = ', response_result);

        const success : boolean            = response_result?.success ?? false;
        const token   : string | undefined = response_result?.token;

        if (success) {

            if (!token) {
                LOG.error('Server did not provide token.');
                return {success:false};
            }

            SessionService.setSessionToken(token);

            LOG.info('Login success.');

        }

        return {success};

    }

}

export default LoginService;
