import React from "react";
import MainMenu from "../../components/mainMenu/MainMenu";

export interface PlayListViewState {

}

export interface PlayListViewProps {

}

export class PlayListView extends React.Component<PlayListViewProps, PlayListViewState> {

    render () {

        return (
            <div>

                <MainMenu />

            </div>
        );

    }

}

export default PlayListView;
