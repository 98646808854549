import React, {ChangeEvent, FormEvent} from "react";
import "./LoginView.scss";
import {LOGIN_VIEW_CLASS_NAME} from "../../constants/classNames";
import LogService from "../../services/LogService";
import LoginService from "../../services/LoginService";
import RoutePath from "../../constants/RoutePath";
import RouteService from "../../services/RouteService";

const LOG = LogService.createLogger('LoginView');

export interface LoginViewState {

    username: string;

    password: string;

}

export interface LoginViewProps {

}

export interface SubmitCallback {
    (event: FormEvent<HTMLFormElement>) : void;
}

export interface TextInputChange {
    (event: ChangeEvent<HTMLInputElement>) : void;
}

export class LoginView extends React.Component<LoginViewProps, LoginViewState> {

    private readonly _onSubmitCallback         : SubmitCallback;
    private readonly _onUsernameChangeCallback : TextInputChange;
    private readonly _onPasswordChangeCallback : TextInputChange;

    constructor(props: LoginViewProps) {

        super(props);

        this.state = {
            username: '',
            password: ''
        };

        this._onSubmitCallback = this._onSubmit.bind(this);
        this._onUsernameChangeCallback = this._onUsernameChange.bind(this);
        this._onPasswordChangeCallback = this._onPasswordChange.bind(this);

    }

    render () {

        return (
            <div className={LOGIN_VIEW_CLASS_NAME}>

                <form className="form-signin"
                      onSubmit={this._onSubmitCallback}
                >

                    <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>

                    <label htmlFor="username"
                           className="sr-only">Username</label>

                    <input type="text"
                           id="username"
                           className="form-control"
                           placeholder="username"
                           name="username"
                           required
                           autoFocus
                           value={this.state.username}
                           onChange={this._onUsernameChangeCallback}
                    />

                    <label htmlFor="password"
                           className="sr-only">Password</label>

                    <input type="password"
                           id="password"
                           className="form-control"
                           placeholder="Password"
                           required
                           name="password"
                           value={this.state.password}
                           onChange={this._onPasswordChangeCallback}
                    />

                    <input type="submit" className="btn btn-lg btn-primary btn-block" value="Sign in" />

                </form>

            </div>
        );

    }

    private _onSubmit (event: FormEvent<HTMLFormElement>) {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this._login(this.state.username, this.state.password).catch(err => {
            LOG.error('ERROR: ', err);
        });

    }

    // noinspection JSMethodCanBeStatic
    private async _login (username: string, password: string) {

        LOG.debug('Logging as ', username, password);

        LoginService.login(username, password).then(response => {

            LOG.debug('response = ', response);

            if (!response.success) {

                alert("login failed");

            } else {

                RouteService.setRouteTarget(RoutePath.DASHBOARD_INDEX);

            }

        }).catch((err : any) => {

            LOG.error('ERROR: ', err);

            alert("something went wrong");

        });

    }

    private _onUsernameChange (event: ChangeEvent<HTMLInputElement>) {

        const newValue : string = event.target?.value ?? '';

        if (this.state.username !== newValue) {
            this.setState({
                ...this.state,
                username: newValue
            });
        }

    }

    private _onPasswordChange (event: ChangeEvent<HTMLInputElement>) {

        const newValue : string = event.target?.value ?? '';

        if (this.state.password !== newValue) {
            this.setState({
                ...this.state,
                password: newValue
            });
        }

    }

}

export default LoginView;
