import {SESSION_LOCALSTORAGE_KEY} from "../constants/backend";
import LogService from "./LogService";

const LOG = LogService.createLogger('SessionService');

export class SessionService {

    public static getSessionToken () : string | undefined {
        return localStorage.getItem(SESSION_LOCALSTORAGE_KEY) ?? undefined;
    }

    public static unsetSessionToken () {

        localStorage.removeItem(SESSION_LOCALSTORAGE_KEY);

        LOG.debug('Session token removed');

    }

    public static setSessionToken (token: string) {

        localStorage.setItem(SESSION_LOCALSTORAGE_KEY, token);

        LOG.debug('Session token defined: ', token);

    }

}

export default SessionService;
