import React from "react";
import Button from "react-bootstrap/Button";
import Form from '@rjsf/material-ui'
import BackendService from "../../services/BackendService";
import LogService from "../../services/LogService";

const LOG = LogService.createLogger('AddUserView');

// From @rjsf/material-ui:
export type FieldError = string;

type FieldValidation = {
    __errors: FieldError[];
    addError: (message: string) => void;
};

type FormValidation = FieldValidation & {
    [fieldName: string]: FieldValidation;
};

//
const USER_SCHEMA : Record<string, any> = {
    "title": "Add user",
    "type": "object",
    "required": ["username", "password1", "password2"],
    "properties": {
        "username": { "type": "string", "title": "username " },
        "password1": { "type": "string", "title": "Password ", "minLenght": 3 },
        "password2": { "type": "string", "title": "Repeat password ", "minLenght": 3 }
    }
};

const UI_SCHEMA : Record<string, any> = {
    "password1": { "ui:widget": "password" },
    "password2": { "ui:widget": "password" }
};

/**
 * This is the type for USER_SCHEMA
 */
interface UserFormData {

    username  : string;
    password1 : string;
    password2 : string;

}

enum AddUserFormState {
    LOADING,
    FORM,
    REQUEST,
    SUBMITTED,
    ERROR
}

export interface AddUserViewState {

    formState: AddUserFormState;

}

export interface AddUserViewProps {

}

interface AddUserSubmitCallback {
    ({formData} : {formData: UserFormData}) : void;
}

interface AddUserValidateCallback {
    (formData : UserFormData | undefined, errors: FormValidation) : FormValidation;
}

export class AddUserView extends React.Component<AddUserViewProps, AddUserViewState> {

    private readonly _onSubmitCallback   : AddUserSubmitCallback;
    private readonly _onValidateCallback : AddUserValidateCallback;

    constructor(props: AddUserViewProps) {

        super(props);

        this.state = {
            formState: AddUserFormState.LOADING
        };

        this._onSubmitCallback   = this._onSubmit.bind(this);
        this._onValidateCallback = this._onValidate.bind(this);

    }

    componentDidMount() {

        this.setState({
            ...this.state,
            formState: AddUserFormState.FORM
        });

    }

    render () {

        switch(this.state.formState) {
            case AddUserFormState.LOADING:
                return <div>Loading...</div>;

            case AddUserFormState.FORM:
                return (
                    <Form schema={USER_SCHEMA}
                          uiSchema={UI_SCHEMA}
                          autoComplete='off'
                        // formData={this.state.formData}
                          validate={this._onValidateCallback}
                          onSubmit={this._onSubmitCallback}
                    >
                        <Button type="submit">Create</Button>
                    </Form>
                );

            case AddUserFormState.REQUEST:
                return <div>Submitting...</div>

            case AddUserFormState.SUBMITTED:
                return <div>User added!</div>

            case AddUserFormState.ERROR:
                return <div>There was an error. User was not created.</div>

        }

        return <div>Unimplemented form state.</div>

    }

    private _onValidate (formData : UserFormData | undefined, errors: FormValidation) : FormValidation {

        if (formData) {
            if (formData.password1 !== formData.password2) {
                errors.password2.addError("passwords don't match");
            }
        } else {
            errors.addError('no input');
        }

        return errors;

    }

    private _onSubmit ({formData} : {formData: UserFormData}) {

        if (!formData) {
            LOG.error('No formData');
            return;
        }

        if (formData["password1"] === formData["password2"]) {

            let headerFormat : Record<string, any> = {
                "user-name": formData["username"],
                "password": formData["password1"]
            };

            LOG.debug("_onSubmit", headerFormat);

            this.setState({
                ...this.state,
                formState: AddUserFormState.REQUEST
            });

            BackendService.addUser(headerFormat).then( () => {

                this.setState({
                    ...this.state,
                    formState: AddUserFormState.SUBMITTED
                });

            }).catch((err: any) => {
                LOG.error('ERROR: ', err);
                this.setState({
                    ...this.state,
                    formState: AddUserFormState.ERROR
                });
            });

        } else {
            LOG.error('Passwords did not match');
        }

    }

}

export default AddUserView;
