import React from "react";
import BackendService, {BackendServiceEvent, MediaFileCollectionDTO, MediaFileDTO} from "../../services/BackendService";
import MediaFileItem from "../mediaFileItem/MediaFileItem";
import Table from 'react-bootstrap/Table';
import {keys, map} from "../../modules/lodash";
import LogService from "../../services/LogService";

const LOG = LogService.createLogger('UploadFile');

export interface UploadFileProps {

}

export interface MediaFile {

    elementId    : string;
    playlistUrl  : string;
    elementName  : string;

}

export interface UploadFileState {

    element    : any;
    mediaFiles : Array<MediaFile>;

}

export class UploadFile extends React.Component<UploadFileProps, UploadFileState> {

    private readonly _fileInputRef : React.RefObject<HTMLInputElement>;

    constructor(props : UploadFileProps) {

        super(props);

        this.state = {
            element: null,
            mediaFiles: []
        };

        this._fileInputRef = React.createRef();

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this)

    }

    async componentDidMount() {

        BackendService.on(BackendServiceEvent.MEDIA_FILE_DELETE, (event, mediaFileUrl : string) => {

            // FIXME: This might end in a race condition

            this._loadData().catch(err => {
                LOG.error('Error: ', err);
            });

            LOG.debug("media_file_delete, event: ", event);

        });

        BackendService.on(BackendServiceEvent.MEDIA_FILE_ADDED, (event, mediaFileUrl : string) => {

            // FIXME: This might end in a race condition

            this._loadData().catch(err => {
                LOG.error('Error: ', err);
            });

        });

        this._loadData().catch(err => {
            LOG.error('ERROR: ', err);
        });

    }

    onChangeHandler () {

        const element : HTMLInputElement | undefined | null = this._fileInputRef?.current ?? undefined;

        if (!element) {
            LOG.error('No file input element detected');
            return;
        }

        const files = element?.files ?? [];

        if (files.length) {

            this.setState({
                element: files[0]
            });

        } else {
            LOG.error('Invalid input: ', files);
        }

    }

    async onClickHandler() {

        const data = new FormData();

        LOG.debug(this.state.element);
        LOG.debug(this.state.element.type);

        data.append("element", this.state.element, this.state.element.name);

        const response = await BackendService.uploadElement(data);

        LOG.debug("UploadFile: ", response);

        if (response?.success === true) {
            LOG.debug("Element was successfully uploaded");
            alert("File was uploaded");
        } if(response?.success === false) {
            LOG.debug("Failed to upload element");
            alert("Failed to upload file");
        }

    }

    render() {

        return (
            <div className="container">

                <div className="form-group files">
                    <label>Upload element:</label> <br />
                    <input type="file"
                           ref={this._fileInputRef}
                           name="file"
                           onChange={this.onChangeHandler}
                           className="form-controller"
                    />
                </div>

                <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler}>Upload file</button>

                <div>
                    <h4>Files:</h4>
                    <Table striped hover>
                        <tbody>
                        {map(this.state.mediaFiles, (child : MediaFile) => <MediaFileItem key={child.elementId} media_file={child} />)}
                        </tbody>
                    </Table>
                </div>
            </div>

        )
    }

    private async _loadData () {

        const media_files : MediaFileCollectionDTO = await BackendService.getMediaFiles();

        const mediaFiles : Array<MediaFile> = map(keys(media_files), (key: string) => {

            const media_file : MediaFileDTO = media_files[key];

            //LOG.debug("Media file: ", media_file);

            return {
                elementId   : key,
                playlistUrl : media_file.playlistUrl,
                elementName : media_file.elementName
            };

        });

        this.setState({
            ...this.state,
            mediaFiles: mediaFiles
        });

        //LOG.debug("Media files: ", mediaFiles);

    }

}

export default UploadFile;
