import React from "react";
import {Link} from "react-router-dom";
import Form from "@rjsf/material-ui";
import Button from "react-bootstrap/Button";
import PLAYLIST_SCHEMA from '../../schemas/playlistSchema.json';
import PLAYLIST_UI_SCHEMA from '../../schemas/playlistUiSchema.json';
import BackendService, {MediaFileCollectionDTO, MediaFileDTO} from "../../services/BackendService";
import {keys, map} from "../../modules/lodash";
import RoutePath from "../../constants/RoutePath";
import RouteService from "../../services/RouteService";
import LogService from "../../services/LogService";
import PlaylistSchema, {PlaylistElementObject} from "../../types/PlaylistObject";
import { find } from "lodash";

const LOG = LogService.createLogger('NewPlaylistView');

export interface NewPlaylistData extends PlaylistSchema {

}

export interface NewViewState {

    formData: NewPlaylistData;

    schema : Record<string, any>;

    dataFromApi : MediaFileCollectionDTO | undefined;

}

export interface NewViewProps {

}

export interface SubmitCallback {
    ({ formData } : {formData: NewPlaylistData}) : void;
}

export class NewPlaylistView extends React.Component<NewViewProps, NewViewState> {

    private readonly _onSubmitCallback : SubmitCallback;

    constructor(props: NewViewProps) {

        super(props);

        this.state = {
            formData: {

            },
            dataFromApi: undefined,
            schema: PLAYLIST_SCHEMA
        }

        this._onSubmitCallback = this._onSubmit.bind(this);

    }

    componentDidMount() {

        this._initData().catch(err => {
            LOG.error('ERROR: ', err);
        });

    }

    render () {

        return (
            <div>

                <Link to={RoutePath.PLAYLIST_COLLECTION} className="btn btn-primary">Back</Link>

                <Form schema={this.state.schema}
                      uiSchema={PLAYLIST_UI_SCHEMA}
                      formData={this.state.formData}
                      onSubmit={this._onSubmitCallback}>
                    <Button type="submit">Save</Button>
                </Form>

            </div>
        );

    }

    private async _initData () {

        const dataFromApi : MediaFileCollectionDTO = await BackendService.getMediaFiles();

        const schema = {
            ...this.state.schema
        };

        schema["properties"]["elements"]["items"]["properties"]["playlistUrl"]["enum"] = map(keys(dataFromApi), (key: string) => {

            const item : MediaFileDTO = dataFromApi[key];

            return item.playlistUrl;

        });

        schema["properties"]["mask"]["properties"]["imageUrl"]["enum"] = map(keys(dataFromApi), (key: string) => {

            const item : MediaFileDTO = dataFromApi[key];

            return item.playlistUrl;

        });

        this.setState({
            ...this.state,
            schema,
            dataFromApi
        });

    }

    // noinspection JSMethodCanBeStatic
    private _onSubmit ({formData} : {formData: NewPlaylistData}) {

        const config_name : string | null = prompt("Please name this new playlist:");

        if (!config_name) {
            LOG.error('_onSubmit: User did not provide name');
            return;
        }

        this._addPlaylist(config_name, formData).catch((err: any) => {
            LOG.error('_onSubmit: Error: ', err);
        });

    }

    private async _addPlaylist (
        config_name : string,
        formData: NewPlaylistData
    ) {

        const dataFromApi : MediaFileCollectionDTO | undefined = this.state.dataFromApi;

        if (!dataFromApi) {
            throw new TypeError('NewPlaylistView: _addPlaylist: Component has no media file data');
        }

        formData = {
            ...formData
        };

        LOG.debug('_addPlaylist: ', config_name, formData);

        if (formData?.crossfade && formData?.crossfade?.enabled === undefined) {

            formData.crossfade = {
                ...formData.crossfade,
                enabled: false
            };

        }

        if (formData?.mask && formData?.mask?.enabled === undefined) {

            formData.mask = {
                ...formData.mask,
                enabled: false
            };

        }

        if (formData?.elements?.length) {

            formData.elements = map(formData.elements, (item : PlaylistElementObject) : PlaylistElementObject => {

                const playlistUrl = item.playlistUrl;

                const elementKey : string | undefined = find(keys(dataFromApi), (key: string) => {

                    const i : MediaFileDTO = dataFromApi[key];

                    return i?.playlistUrl === playlistUrl;

                });

                if (elementKey === undefined) {
                    throw new TypeError(`NewPlaylistView: Could not find media file for ${playlistUrl}`);
                }

                const elementId : number = parseInt(elementKey, 10);

                if (elementId === undefined) {
                    throw new TypeError(`NewPlaylistView: Could not find media file id for ${playlistUrl}`);
                }

                return {
                    ...item,
                    elementId: elementId
                };
            });

        }

        const response = await BackendService.addNewPlaylist(config_name, formData);

        LOG.debug("_addPlaylist: response", response);

        RouteService.setRouteTarget(RoutePath.PLAYLIST_COLLECTION);

    }

}

export default NewPlaylistView;
