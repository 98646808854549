import React from "react";
import Form from "@rjsf/material-ui";
import BackendService, {MediaFileCollectionDTO, MediaFileDTO} from "../../services/BackendService";
import PLAYLIST_SCHEMA from '../../schemas/playlistSchema.json';
import PLAYLIST_UI_SCHEMA from '../../schemas/playlistUiSchema.json';
import {Link} from "react-router-dom";
import RoutePath from "../../constants/RoutePath";
import RouteService from "../../services/RouteService";
import {
    isEqual,
    keys,
    map
} from "../../modules/lodash";
import PlaylistObject from "../../types/PlaylistObject";
import PlaylistSchema, {PlaylistElementObject} from "../../types/PlaylistObject";
import LogService from "../../services/LogService";
import {JsonObject} from "../../types/Json";
import {FormProps} from "@rjsf/core";
import { find } from "lodash";

const LOG = LogService.createLogger('EditPlaylistView');

export interface EditPlaylistViewState {

    playlist : PlaylistObject | undefined;

    available_media: MediaFileCollectionDTO | undefined;

    schema   : JsonObject;



}

export interface EditPlaylistViewProps {

    playListId ?: string;

}

interface EditSubmitCallback {
    () : void;
}

interface EditChangeCallback {
    ({ formData } : { formData?: FormProps<any> | undefined }, e: any) : void;
}

export class EditPlaylistView extends React.Component<EditPlaylistViewProps, EditPlaylistViewState> {

    private readonly _onSubmitCallback : EditSubmitCallback;
    private readonly _onChangeCallback : EditChangeCallback;

    constructor(props: EditPlaylistViewProps) {

        super(props);

        this.state = {
            playlist: undefined,
            available_media: undefined,
            schema: PLAYLIST_SCHEMA
        }

        this._onSubmitCallback = this._onSubmit.bind(this);
        this._onChangeCallback = this._onChange.bind(this);

    }

    componentDidMount() {

        this._fetchData().catch(err => {
            LOG.error('ERROR: ', err);
        });


    }

    render () {

        return (
            <div>
                <Link to={RoutePath.PLAYLIST_COLLECTION} className="btn btn-primary">Back</Link>

                <Form schema={this.state.schema}
                      uiSchema={PLAYLIST_UI_SCHEMA}
                      formData={this.state.playlist}
                      onChange={this._onChangeCallback}
                      onSubmit={this._onSubmitCallback}
                >
                    <button type="submit">Save</button>
                </Form>

            </div>
        );

    }

    private async _fetchData () {

        const playlistId = this.props.playListId;

        if (!playlistId) {
            LOG.error('No playListId provided');
            return;
        }

        let playlist = await BackendService.getPlaylist(playlistId);

        const available_media : MediaFileCollectionDTO = await BackendService.getMediaFiles();

        LOG.debug('available_media: ', available_media);

        let schema : any = {
            ...this.state.schema
        };

        schema.properties['elements']['items']['properties']['playlistUrl']['enum'] = map(keys(available_media), (key: string) => {
            const item : MediaFileDTO = available_media[key]; 
            return available_media[key].playlistUrl;
        });

        schema.properties['mask']['properties']['imageUrl']['enum'] = map(keys(available_media), (key: string) => {
            const item : MediaFileDTO = available_media[key]; 
            return available_media[key].playlistUrl;
        });

        

        if (
            !isEqual(this.state.playlist, playlist)
            || !isEqual(this.state.schema, schema)
        ) {
            this.setState({
                ...this.state,
                playlist: playlist,
                schema: schema,
                available_media
            });
        }

    }

    private _onChange ({formData} : { formData ?: FormProps<any> | undefined }, e: any) {

        const playlist = formData as PlaylistObject;

        if (!isEqual(this.state.playlist, playlist)) {

            this.setState({
                ...this.state,
                playlist: playlist
            });

            LOG.debug('Changed: ', playlist);

        } else {
            LOG.debug('No changes');
        }

    }

    // noinspection JSMethodCanBeStatic
    private async _onSubmit () {

        const available_media : MediaFileCollectionDTO | undefined = this.state.available_media;

        const playlist = this.state.playlist;

        if (!playlist) {
            LOG.error("No playlist defined");
            return;
        }

        if (!available_media) {
            throw new TypeError('EditPlaylistView: _onSubmit: Component has no media file data');
        }

        if (playlist?.crossfade && playlist?.crossfade?.enabled === undefined) {

            playlist.crossfade = {
                ...playlist.crossfade,
                enabled: false
            };

        }

        if((playlist?.mask && playlist?.mask?.enabled === undefined) || playlist?.mask === undefined || playlist?.mask.enabled === undefined) {
            playlist.mask = {
                ...playlist.mask,
                enabled: false,
                imageUrl: '',
                lightness: 1
            };
        } else if(playlist?.mask.enabled === false) {
            let lightness = playlist.mask?.lightness;
            let imageUrl = playlist.mask?.imageUrl;

            if(imageUrl === undefined)
                imageUrl = '';
            if(lightness === undefined)
                lightness = 1;
            playlist.mask = {
                ...playlist.mask,
                enabled: false,
                imageUrl: imageUrl,
                lightness: lightness
            }
        }


        if (playlist?.elements?.length) {

            playlist.elements = map(playlist.elements, (item : PlaylistElementObject) : PlaylistElementObject => {

                const playlistUrl = item.playlistUrl;

                const elementKey : string | undefined = find(keys(available_media), (key: string) => {

                    const i : MediaFileDTO = available_media[key];

                    return i?.playlistUrl === playlistUrl;

                });

                if (elementKey === undefined) {
                    throw new TypeError(`NewPlaylistView: Could not find media file for ${playlistUrl}`);
                }

                const elementId : number = parseInt(elementKey, 10);

                if (elementId === undefined) {
                    throw new TypeError(`NewPlaylistView: Could not find media file id for ${playlistUrl}`);
                }

                return {
                    ...item,
                    elementId: elementId
                };
            });

        }

        /*
        if(playlist?.mask?.enabled) {
            const elementKey : string | undefined = find(keys(available_media), (key: string) => {

                const i : MediaFileDTO = available_media[key];

                return i?.playlistUrl === playlist.mask?.imageUrl;

            });

            if (elementKey === undefined) {
                throw new TypeError(`NewPlaylistView: Could not find media file for ${playlist.mask?.imageUrl}`);
            }

            const elementId : number = parseInt(elementKey, 10);

            if (elementId === undefined) {
                throw new TypeError(`NewPlaylistView: Could not find media file id for ${playlist.mask?.imageUrl}`);
            }

            return {
                ...item,
                elementId: elementId
            };
        }*/

        const response = await BackendService.editPlaylist(playlist);

        LOG.debug("response", response);

        RouteService.setRouteTarget(RoutePath.DASHBOARD_INDEX);

    }

}

export default EditPlaylistView;
