import {
    API_ADDR,
    BACKEND_SESSION_HEADER, DEV_PROXY_BACKEND_URL, IS_DEVELOPMENT
} from "../constants/backend";
import RouteService from "./RouteService";
import RoutePath from "../constants/RoutePath";
import SessionService from "./SessionService";
import LogService from "./LogService";
import {has, replace} from "../modules/lodash";
import {JsonObject} from "../types/Json";
import PlaylistObject from "../types/PlaylistObject";
import Observer, {ObserverCallback, ObserverDestructor} from "./Observer";

const LOG = LogService.createLogger('BackendService');

export type HeadersType = Record<string, string>;

export interface MediaFileDTO {

    elementName    : string;
    mediaLocation  : string;
    playlistUrl    : string;

}

export interface MediaFileCollectionDTO {

    [key: string]: MediaFileDTO;

}

export interface PlaylistDTO {

    playlistId       : number;
    playlistName     : string;
    playlistVersion  : number;

}

export interface PlaylistCollectionDTO {

    playlists: Array<PlaylistDTO>;

}

export interface UploadResponse {

    success: boolean;

}

export interface FileDeleteResponse {

    success: boolean;
    msg: string;

}

export enum BackendServiceEvent {

    PLAYLIST_DELETE = "BackendService:playlistDeleted",

    MEDIA_FILE_ADDED = "BackendService:mediaFileAdded",
    MEDIA_FILE_DELETE = "BackendService:mediaFileDelete"

}

export class BackendError extends Error {

    private readonly _status   : number;
    private readonly _response : Response;

    constructor (statusCode: number, response : Response) {

        super(`Backend error ${statusCode}`);

        this._status = statusCode;
        this._response = response;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BackendError.prototype);

    }

    get status () : number {
        return this._status;
    }

    get response () : Response {
        return this._response;
    }

    toString () : string {
        return `BackendError(#${this._status})`;
    }

}
export class BackendService {

    public static Event = BackendServiceEvent;

    private static _observer : Observer<BackendServiceEvent> = new Observer("BackendService");

    public static on (name : BackendServiceEvent, callback : ObserverCallback<BackendServiceEvent>) : ObserverDestructor {
        return this._observer.listenEvent(name, callback);
    }

    public static async getPlaylists () : Promise<PlaylistCollectionDTO> {

        LOG.debug("getPlaylists");

        return await this._pollApi("playlists");

    }

    /**
     * @fixme Fix return type
     * @param id
     */
    public static async getPlaylist (id : string) : Promise<any> {
        return await this._pollApi("playlists/" + id);
    }

    public static async editPlaylist (data: PlaylistObject) {

        LOG.debug("editPlaylist: ", data);

        return await this._postDataWithJson("edit/playlist", data as JsonObject);

    }

    private static _createHeaders (headerData : HeadersType | undefined = undefined) : HeadersType {

        let headers : HeadersType = headerData ? headerData : {};

        const token : string | undefined = SessionService.getSessionToken();

        if (token) {
            headers = {
                ...headerData,
                [BACKEND_SESSION_HEADER]: token
            };
        }

        return headers;

    }

    public static async postHeaders (
        apiPath    : string,
        headerData : HeadersType | undefined = undefined
    ) {

        const headers : HeadersType = BackendService._createHeaders(headerData);

        LOG.debug("headers: ", headers);

        const response = await fetch(API_ADDR + apiPath, {
            method: "POST",
            headers: headers,
        });

        this._checkAuthentication(response);

        return response;

    }

    /**
     * @fixme Fix type for data
     * @param name
     * @param data
     */
    public static async addNewPlaylist (
        name : string,
        data : any
    ) {

        LOG.debug("addNewPlaylist: " + name, data);

        return await this._postDataWithJson("new/playlist/" + name, data);

    }

    public static async getMediaFiles () : Promise<MediaFileCollectionDTO> {
        return await this._pollApi("available/media");
    }

    public static async getMediaFile (fileUrl: string) {

        // Fix media URLs
        if (IS_DEVELOPMENT && DEV_PROXY_BACKEND_URL !== API_ADDR) {
            fileUrl = replace(fileUrl, DEV_PROXY_BACKEND_URL, API_ADDR);
        }

        return await this._rawGet(fileUrl)
    }

    public static async deleteMediaFile (fileUrl : string) : Promise<FileDeleteResponse> {

        // Fix media URLs
        if (IS_DEVELOPMENT && DEV_PROXY_BACKEND_URL !== API_ADDR) {
            fileUrl = replace(fileUrl, DEV_PROXY_BACKEND_URL, API_ADDR);
        }

        const response = await this._rawDelete(fileUrl);

        this._checkAuthentication(response);

        const responseData = await response.json();

        this._observer.triggerEvent(BackendServiceEvent.MEDIA_FILE_DELETE, fileUrl);

        return responseData;

    }

    public static async addUser (data: Record<string, string>) {

        LOG.debug("adding user");

        return await this._postData("db/user", data);

    }

    public static async uploadElement (element : FormData) : Promise<UploadResponse> {

        const api_path = API_ADDR + "new/element";

        LOG.debug("uploadElement: ", element);

        const headers : HeadersType = BackendService._createHeaders();

        let response : Response = await fetch(api_path, {
            method: "POST",
            headers: headers,
            body: element
        });

        LOG.debug("uploadElement: response: ", response);

        this._checkAuthentication(response);

        const responseData = await response.json();

        this._observer.triggerEvent(BackendServiceEvent.MEDIA_FILE_ADDED);

        return responseData;

    }

    private static async _pollApi (api_path: string) : Promise<any> {

        const headers : HeadersType = BackendService._createHeaders();

        const config = await fetch(API_ADDR + api_path, {
            headers: headers
        });

        //LOG.debug(typeof config);

        this._checkAuthentication(config);

        const statusCode = config?.status ?? 0;

        if (statusCode < 200 || statusCode >= 400 ) {
            throw new BackendError(statusCode, config);
        }

        return await config.json();

    }

    private static async _rawGet (url : string) {

        const headers : HeadersType = BackendService._createHeaders();

        LOG.debug("headers", headers);
        LOG.debug("fileUrl: " + url)

        const response : Response = await fetch(url, {
            method: "GET",
            headers: headers
        });

        //LOG.debug("Get response: ", response);
        //var returned = await response;
        this._checkAuthentication(response);

        const statusCode = response?.status ?? 0;

        if (statusCode < 200 || statusCode >= 400 ) {
            throw new BackendError(statusCode, response);
        }

        return response;

    }

    private static _checkAuthentication (response : Response) {

        try {
            //LOG.debug(response);
            //LOG.debug(response.status);
            if (!response.ok) {

                if (response.status === 409) {

                    alert("Media file was not uploaded please check if it already exists.");

                } else if (response.status === 403) {

                    alert("Login session has expired.");

                    SessionService.unsetSessionToken();
                    RouteService.setRouteTarget(RoutePath.INDEX);

                } else if (response.status === 500) {
                    alert("Something went wrong");
                    //LOG.debug(response)
                }

            } else {

                //alert("File has been uploaded");

            }
        } catch (err) {
            if (err instanceof TypeError) {
                LOG.debug("im going here");
                LOG.debug('request was most likely successful')
            } else {
                throw err
            }
        }

    }

    private static async _rawDelete (url : string) {

        const headers : HeadersType = BackendService._createHeaders();

        LOG.debug("headers: ", headers);
        LOG.debug("fileUrl: " + url)

        const response = await fetch(url, {
            method: "DELETE",
            headers: headers
        });

        this._checkAuthentication(response);

        const statusCode = response?.status ?? 0;

        if (statusCode < 200 || statusCode >= 400 ) {
            throw new BackendError(statusCode, response);
        }

        return response;

    }

    private static async _postData (
        api_path    : string,
        header_data : HeadersType | undefined  = undefined
    ) {

        const headers : HeadersType = BackendService._createHeaders(header_data);

        LOG.debug(headers);

        let response = await fetch(API_ADDR + api_path, {
            method: "POST",
            headers: headers,
            body: undefined
        });

        this._checkAuthentication(response);

        const statusCode = response?.status ?? 0;

        if (statusCode < 200 || statusCode >= 400 ) {
            throw new BackendError(statusCode, response);
        }

        return response;

    }

    private static async _postDataWithJson (
        apiPath    : string,
        bodyData   : JsonObject | undefined = undefined,
        headerData : HeadersType | undefined  = undefined,
    ) {

        let headers : HeadersType = BackendService._createHeaders(headerData);

        if (!has(headers, "Content-Type")) {
            headers = {
                ...headers,
                "Content-Type": "application/json"
            };
        }

        LOG.debug("headers", headers);
        LOG.debug("bodyData", bodyData);

        const response = await fetch(API_ADDR + apiPath, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(bodyData)
        });

        LOG.debug('response = ', response);

        this._checkAuthentication(response);

        const statusCode = response?.status ?? 0;

        if (statusCode < 200 || statusCode >= 400 ) {
            throw new BackendError(statusCode, response);
        }

        return response;

    }

    private static async _rawPost (api_path: string) {

        const headers : HeadersType = BackendService._createHeaders();

        const response = await fetch(API_ADDR + api_path, {
            method: "POST",
            headers: headers
        });

        this._checkAuthentication(response);

        return response;

    }

}

export default BackendService;
