import React, {useEffect} from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    useParams,
    useHistory
} from "react-router-dom";
import LoginView from "../../views/loginView/LoginView";
import PlayListView from "../../views/playListView/PlayListView";
import UploadView from "../../views/uploadView/UploadView";
import AddUserView from "../../views/addUserView/AddUserView";
import EditPlaylistView from "../../views/editView/EditPlaylistView";
import ConfigureView from "../../views/configureView/ConfigureView";
import RouteService from "../../services/RouteService";
import RoutePath from "../../constants/RoutePath";
import NewPlaylistView from "../../views/newPlaylistView/NewPlaylistView";
import LogoutView from "../../views/logoutView/LogoutView";
import LogService from "../../services/LogService";

const LOG = LogService.createLogger('App');

function EditViewWithId () {

    let { playListId } : {playListId ?: string} = useParams();

    return (
        <EditPlaylistView playListId={playListId} />
    );

}

function AppWithHistory () {

    const history = useHistory();

    useEffect(() => {

        return RouteService.on(RouteService.Event.PUSH_HISTORY, (event: any, appTarget: RoutePath) => {

            LOG.debug('App: Changing route to: ', appTarget);

            history.push(appTarget);

        });

    }, [history]);

    return (
        <Switch>

            <Route path="/dashboard">

                <main className="container">

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink to={RoutePath.PLAYLIST_COLLECTION} className="nav-link" activeClassName="active"
                            >Playlists</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={RoutePath.UPLOAD} className="nav-link" activeClassName="active">Upload elements</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={RoutePath.ADD_USER} className="nav-link" activeClassName="active">Add user</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={RoutePath.LOGOUT} className="nav-link" activeClassName="active">Logout</NavLink>
                        </li>
                    </ul>

                    <hr />

                    <Route path={RoutePath.PLAYLIST_EDIT}>
                        <EditViewWithId />
                    </Route>
                    <Route exact path={RoutePath.PLAYLIST_COLLECTION}>
                        <PlayListView />
                    </Route>
                    <Route path={RoutePath.UPLOAD}>
                        <UploadView />
                    </Route>
                    <Route path={RoutePath.ADD_USER}>
                        <AddUserView />
                    </Route>
                    <Route path={RoutePath.NEW_PLAYLIST}>
                        <NewPlaylistView />
                    </Route>
                    <Route path={RoutePath.LOGOUT}>
                        <LogoutView />
                    </Route>

                    <Route exact path={RoutePath.DASHBOARD_INDEX}>
                        <ConfigureView />
                    </Route>

                </main>

            </Route>

            <Route path={RoutePath.INDEX}>
                <LoginView />
            </Route>

        </Switch>
    );

}

function App () {

    return (
        <div className="App">
            <Router>
                <AppWithHistory />
            </Router>
        </div>
    );
}

export default App;
