
export enum RoutePath {

    PLAYLIST_EDIT = "/dashboard/playlists/:playListId",

    PLAYLIST_COLLECTION = "/dashboard/playlists",

    UPLOAD = "/dashboard/upload",

    ADD_USER = "/dashboard/addUser",

    NEW_PLAYLIST = "/dashboard/newPlaylist",

    DASHBOARD_INDEX = "/dashboard",

    LOGOUT = "/logout",

    INDEX = "/"

}

export function getPlaylistEditRoutePath (id : string) : string {
    return RoutePath.PLAYLIST_COLLECTION + "/" + encodeURIComponent(id);
}

export default RoutePath;
