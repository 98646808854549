import React from "react";
import MainMenu from "../../components/mainMenu/MainMenu";

export interface ConfigureViewState {

}

export interface ConfigureViewProps {

}

export class ConfigureView extends React.Component<ConfigureViewProps, ConfigureViewState> {

    render () {

        return (
            <div>

                <MainMenu />

            </div>
        );

    }

}

export default ConfigureView;
